<template>
  <ion-list v-if="resources.data.length > 0">
    <ion-item-sliding
      v-for="item in resources.data"
      :key="item.id"
      :ref="`items-${item.id}`"
    >
      <ion-item>
        <ion-label
          class="link"
          :router-link="{ name: 'invoice.show', params: { invoice: item.id } }"
        >
          <h2>{{ item.name }}</h2>
          <h4>Invoice No: {{ item.id }}</h4>
          <p>
            {{ item.user.user_detail.name }} -
            {{ item.user.student.section_standard.standard.name }} ({{
              item.user.student.section_standard.section.name
            }})
          </p>
          <p>Invoice Amount: Rs. {{ item.gross_amount_in_cent / 100 }}</p>
        </ion-label>
        <ion-buttons slot="end">
          <ion-button
            :href="`${base_url}api/v1/fee-invoice-print/${item.id}?pat=${token}`"
            :download="`fee_invoice_${item.id}`"
          >
            <ion-icon
              slot="icon-only"
              :ios="documentOutline"
              :md="documentSharp"
              size="small"
            ></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-item>
    </ion-item-sliding>
  </ion-list>
  <ion-list v-else>
    <ion-list-header>
      <p>No invoices found</p>
    </ion-list-header>
  </ion-list>
</template>
<script>
import base_url from "../../../../apis/base_url";
import {
  IonList,
  IonListHeader,
  IonLabel,
  IonItemSliding,
  IonItem,
  IonButtons,
  IonButton,
  IonIcon,
  alertController,
} from "@ionic/vue";

import { documentOutline, documentSharp } from "ionicons/icons";

export default {
  props: {
    resources: {
      type: Object,
      required: true,
    },
  },

  components: {
    IonList,
    IonListHeader,
    IonLabel,
    IonItemSliding,
    IonItem,
    IonButtons,
    IonButton,
    IonIcon,
  },
  data() {
    return {
      documentOutline,
      documentSharp,
      base_url,
      xhrError: {
        header: null,
        description: null,
      },
    };
  },

  computed: {
    filter() {
      return this.$store.getters["invoice/segment"];
    },
    token() {
      return this.$store.getters["auth/token"];
    },
  },

  methods: {
    alerter(header, message, buttons = ["close"]) {
      const alert = alertController.create({
        header: header,
        message: message,
        buttons: buttons,
      });
      return alert;
    },
    setErrorResponse(error) {
      if (error.response) {
        this.xhrError.header = error.response.data.header;
        this.xhrError.message = error.response.data.message;
      } else if (error.request) {
        console.log(error.request);
        this.xhrError.header = error.message || error.request;
        this.xhrError.message = "Please check your connection and try again";
      } else {
        this.xhrError.header = error.message;
        this.xhrError.message = "Something went wrong. Try again later";
      }
    },
  },
};
</script>